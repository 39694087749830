.how-works-main {
  min-height: 80vh;
  max-width: 100vw;
  background-image: url("../../../Assets/blur-bg.png");
  background-size: cover;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 600px) {
  .how-works-main {
    padding: 40px;
  }
}
@media screen and (min-width: 900px) {
  .how-works-main {
    min-height: 67vh;
  }
}
@media screen and (min-width: 1200px) {
  .how-works-main {
    padding-left: 130px;
    padding-right: 130px;
  }
}

.how-works-main-heading {
  color: var(--font-color-dark);
  font-family: var(--font-family);
  font-size: var(--font-size-md);
  font-style: normal;
  font-weight: 500;
  margin-bottom: 20px;
}
@media screen and (min-width: 900px) {
  .how-works-main-heading {
    font-size: var(--font-size-lg);
    margin-bottom: 100px;
  }
}
@media screen and (min-width: 1200px) {
  .how-works-main-heading {
    font-size: var(--font-size-xlg);
    margin-bottom: 100px;
  }
}

.how-works-main-heading-span {
  color: var(--font-color-orange);
  position: relative;
}
.how-works-main-heading-span::after {
  content: "";
  position: absolute;
  width: 98%;
  border-top: solid 4px var(--font-color-orange);
  left: 0;
  bottom: -10px;
  border-top-left-radius: 90%;
  border-top-right-radius: 0%;
  height: 10px;
}

.how-works-list-card {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
@media screen and (min-width: 900px) {
  .how-works-list-card {
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.how-works-list-item-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.how-works-list-item-image {
  height: 110px;
  width: 100px;
}
@media screen and (min-width: 900px) {
  .how-works-list-item-image {
    height: 160px;
    width: 150px;
  }
}

@media screen and (min-width: 1200px) {
  .how-works-list-item-image {
    height: auto;
    width: auto;
  }
}

.how-works-list-item-text-card {
  height: 50px;
  width: 200px;
  border-radius: 20px;
  border: 1px solid #fff;
  background: rgba(255, 255, 255, 0.12);
  box-shadow: 0px 4px 64px 0px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  padding: 5px;
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* margin-top: -10px; */
}
@media screen and (min-width: 900px) {
  .how-works-list-item-text-card {
    padding-left: 20px;
  }
}

.how-works-list-item-num-card {
  height: 30px;
  width: 30px;
  background-image: linear-gradient(#ca4b07, #faad83);
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: -10%;
  color: var(--font-color-dark);
  font-size: 14px;
  font-weight: 500;
}
@media screen and (min-width: 900px) {
  .how-works-list-item-num-card {
    height: 40px;
    width: 40px;
  }
}
@media screen and (min-width: 1200px) {
  .how-works-list-item-num-card {
    height: 45px;
    width: 45px;
    font-size: 16px;
    font-weight: 600;
  }
}

@media screen and (min-width: 900px) {
  .how-works-list-item-text-card {
    height: 70px;
    width: 240px;
  }
}
@media screen and (min-width: 1200px) {
  .how-works-list-item-text-card {
    height: 70px;
    width: 280px;
  }
}

.how-works-list-item-work-text {
  color: var(--font-color-dark);
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 14px;
  text-align: center;
}
@media screen and (min-width: 900px) {
  .how-works-list-item-work-text {
    font-size: 15px;
  }
}
@media screen and (min-width: 1200px) {
  .how-works-list-item-work-text {
    font-size: 17px;
  }
}
