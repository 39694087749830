.initial-page-main {
  min-height: 100vh;
  max-width: 100vw;
  background-image: linear-gradient(120deg, #fdf3ecd8 30%, #e5e8fdbd);
  padding: 20px;
}

@media screen and (min-width: 600px) {
  .initial-page-main {
    padding: 30px;
  }
}

@media screen and (min-width: 900px) {
  .initial-page-main {
    padding: 40px;
  }
}

@media screen and (min-width: 1200px) {
  .initial-page-main {
    padding-left: 120px;
    padding-right: 120px;
  }
}

.initial-page-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 80px;
}

.initial-page-side-design-1 {
  display: none;
  height: 420px;
  width: auto;
  margin-left: -30px;
}

.initial-page-side-design-2 {
  display: none;
  height: 420px;
  width: auto;
  margin-right: -30px;
  margin-top: 30px;
}

@media screen and (min-width: 900px) {
  .initial-page-side-design-1 {
    display: block;
    height: 280px;
    width: auto;
    margin-left: -40px;
  }

  .initial-page-side-design-2 {
    display: block;
    height: 280px;
    width: auto;
    margin-right: -40px;
    margin-top: 130px;
  }
}

@media screen and (min-width: 1200px) {
  .initial-page-side-design-1 {
    display: block;
    height: 420px;
    width: auto;
    margin-left: -120px;
  }

  .initial-page-side-design-2 {
    display: block;
    height: 420px;
    width: auto;
    margin-right: -120px;
    margin-top: 130px;
  }
}

.initial-page-middle-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.initial-page-main-heading {
  color: var(--font-color-dark);
  font-size: var(--font-size-md);
  font-family: var(--font-family);
  font-weight: bolder;
  margin-bottom: 0px;
  text-align: center;
}

.initial-page-main-heading-span {
  color: var(--font-color-blue);
}

@media screen and (min-width: 600px) {
  .initial-page-main-heading {
    font-size: var(--font-size-lg);
  }
}

.initial-page-sub-heading {
  color: var(--font-color-dark);
  font-size: var(--font-size-md);
  font-family: var(--font-family);
  font-weight: 500;
  margin-bottom: 0px;
}

.initial-page-paragraph {
  color: var(--font-color-dark);
  font-size: var(--font-size-sm);
  font-family: var(--font-family);
  font-weight: 400;
  text-align: center;
  line-height: 30px;
  margin-bottom: 32px;
}

.initial-page-button-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  margin-bottom: 40px;
}

.initial-page-know-more-button {
  height: 56px;
  width: 130px;
  border-radius: 16px;
  background-color: var(--font-color-blue);
  border-style: none;
  color: var(--font-color-white);
  font-family: var(--font-family);
  font-size: var(--font-size-sm);
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
}

@media screen and (min-width: 600px) {
  .initial-page-know-more-button {
    height: 56px;
    width: 151px;
  }
}

.initial-page-get-start-button {
  height: 56px;
  width: 151px;
  border-radius: 16px;
  background-color: var(--font-color-white);
  border-style: none;
  color: var(--font-color-orange);
  font-family: var(--font-family);
  font-size: var(--font-size-sm);
  font-style: normal;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.16);
  cursor: pointer;
}

@media screen and (min-width: 600px) {
  .initial-page-get-start-button {
    height: 56px;
    width: 186px;
    gap: 18px;
  }
}

.initial-page-scroll-more-text {
  color: var(--font-color-dark);
  font-size: var(--font-size-sm);
  font-family: var(--font-family);
  font-weight: 400;
  margin-bottom: 10px;
}

.initial-page-doctor-patient-img-card {
  height: fit-content;
  width: fit-content;
  padding: 10px;
  background-color: var(--bg-white);
  border-radius: 22px;
}

.initial-page-doctor-patient-img {
  width: 250px;
  height: 140px;
}

@media screen and (min-width: 600px) {
  .initial-page-doctor-patient-img {
    width: 400px;
    height: 200px;
  }
}

@media screen and (min-width: 900px) {
  .initial-page-doctor-patient-img {
    width: 450px;
    height: 250px;
  }
}

@media screen and (min-width: 1200px) {
  .initial-page-doctor-patient-img-card {
    height: fit-content;
    width: fit-content;
    padding: 15px;
    background-color: var(--bg-white);
    border-radius: 32px;
  }

  .initial-page-doctor-patient-img {
    width: 711px;
    height: 342px;
  }
}
