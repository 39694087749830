.header-item {
  color: var(--font-color-white);
  font-size: var(--font-size-sm);
  font-family: var(--font-family);
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media screen and (min-width: 900px) {
  .header-item {
    color: var(--font-color-dark);
  }
}
.header-item-underline {
  display: block;
  width: 20px;
  height: 4px;
  background-color: #293a60;
  border-radius: 5px;
  margin-top: 6px;
}
