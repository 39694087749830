:root {
  --font-color-dark: #1d2942;
  --font-color-dark-blue: #293a60;
  --font-color-blue: #0070c0;
  --font-color-white: #ffffff;
  --font-color-orange: #de9764;
  --font-size-sm: 17px;
  --font-size-m: 24px;
  --font-size-md: 32px;
  --font-size-lg: 42px;
  --font-size-xlg: 56px;
  --font-family: Roboto;
  --bg-white: #ffffff;
  --bg-dark: #1d2942;
  --bg-dark-blue: #293a60;
}
