.header-main {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.header-company-logo {
  display: block;
  height: 31.84px;
  width: 31.84px;
  cursor: pointer;
}

.header-company-logo-text {
  display: none;
  height: 31.84px;
  width: 234.31px;
  cursor: pointer;
}

@media screen and (min-width: 600px) {
  .header-company-logo {
    display: none;
  }
  .header-company-logo-text {
    display: block;
  }
}

.header-mobile-menu {
  display: block;
  cursor: pointer;
}

@media screen and (min-width: 900px) {
  .header-mobile-menu {
    display: none;
  }
}

@media screen and (max-width: 899px) {
  .header-items-card {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    position: fixed;
    inset: 0 0 0 30%;
    /* left: 100%; */
    background: var(--font-color-blue);
    padding: min(30vh, 5rem) 2rem;
    transition: 0.5s;
  }
}

@media screen and (min-width: 900px) {
  .header-items-card {
    display: block;
    display: flex;
    flex-direction: row;
    gap: 30px;
  }
}

.header-close-button {
  position: "absolute";
  top: "5%";
  left: "85%";
  cursor: pointer;
}

@media screen and (min-width: 900px) {
  .header-close-button {
    display: none;
  }
}
