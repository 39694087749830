.connect-options-main {
  min-height: 80vh;
  max-width: 100vw;
  background-color: #f6f6f9;
  padding: 20px;
}
@media screen and (min-width: 600px) {
  .connect-options-main {
    padding: 40px;
  }
}
@media screen and (min-width: 900px) {
  .connect-options-main {
    min-height: 80vh;
  }
}
@media screen and (min-width: 1200px) {
  .connect-options-main {
    min-height: 100vh;
    padding-left: 130px;
    padding-right: 130px;
  }
}

.connect-options-content-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media screen and (min-width: 900px) {
  .connect-options-content-card {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.connect-options-text-card {
  text-align: center;
}
@media screen and (min-width: 900px) {
  .connect-options-text-card {
    text-align: start;
  }
}

.connect-options-main-heading {
  color: var(--font-color-dark);
  font-size: var(--font-size-md);
  font-family: var(--font-family);
  font-weight: 600;
  margin-bottom: 50px;
}
@media screen and (min-width: 900px) {
  .connect-options-main-heading {
    font-size: var(--font-size-lg);
  }
}
@media screen and (min-width: 1200px) {
  .connect-options-main-heading {
    font-size: var(--font-size-xlg);
  }
}

.connect-options-main-heading-span {
  color: var(--font-color-orange);
  position: relative;
}

.connect-options-main-heading-span::after {
  content: "";
  position: absolute;
  width: 98%;
  border-top: solid 3.5px var(--font-color-orange);
  left: 0;
  bottom: -10px;
  border-top-left-radius: 90%;
  border-top-right-radius: 0%;
  height: 10px;
}

.connect-options-main-paragraph {
  color: var(--font-color-dark);
  font-size: var(--font-size-sm);
  font-family: var(--font-family);
  font-weight: 400;
}
@media screen and (min-width: 900px) {
  .connect-options-main-paragraph {
    font-size: var(--font-size-m);
  }
}

.connect-options-watch-device-img {
  height: 300px;
  width: 250px;
  /* border-style: dashed; */
}

@media screen and (min-width: 600px) {
  .connect-options-watch-device-img {
    height: 400px;
    width: 350px;
  }
}
@media screen and (min-width: 1200px) {
  .connect-options-watch-device-img {
    height: 600px;
    width: 550px;
  }
}

.connect-options-list-card {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media screen and (min-width: 1200px) {
  .connect-options-list-card {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
  }
}

.connect-options-list-item {
  width: 300px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 30px;
}
@media screen and (min-width: 1200px) {
  .connect-options-list-item {
    width: 380px;
  }
}

.connect-options-list-image {
  height: 50px;
  width: 50px;
}
@media screen and (min-width: 1200px) {
  .connect-options-list-image {
    height: 100px;
    width: 100px;
  }
}

.connect-options-list-text-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.connect-options-list-option-text {
  color: var(--font-color-dark);
  font-size: var(--font-size-sm);
  font-weight: 500;
}
@media screen and (min-width: 1200px) {
  .connect-options-list-option-text {
    font-size: var(--font-size-m);
  }
}

.connect-options-learn-more-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.connect-options-list-learn-more-text {
  color: var(--font-color-orange);
  font-size: 15px;
  font-weight: 500;
}
@media screen and (min-width: 1200px) {
  .connect-options-list-learn-more-text {
    font-size: 20px;
    font-weight: 400;
  }
}

.connect-options-learn-more-button {
  height: 15px;
  width: 15px;
  border-style: none;
  background-color: #ff732844;
  box-shadow: inset(2px 2px 4px #8940197a);
}
@media screen and (min-width: 1200px) {
  .connect-options-learn-more-button {
    height: 18px;
    width: 18px;
  }
}
