body::-webkit-scrollbar {
  width: 8px; /* for vertical scrollbars */
  height: 8px; /* for horizontal scrollbars */
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 6px;
}

body::-webkit-scrollbar-track {
  background: #f1f1f1;
}