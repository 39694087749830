.device-features-main {
  min-height: 60vh;
  max-width: 100vw;
  background-color: #fffffff6;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 600px) {
  .device-features-main {
    min-height: 60vh;
    padding: 40px;
  }
}
@media screen and (min-width: 900px) {
  .device-features-main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
@media screen and (min-width: 1200px) {
  .device-features-main {
    min-height: 110vh;
    padding-left: 130px;
    padding-right: 130px;
  }
}

.device-features-running-man-image {
  display: none;
}
@media screen and (min-width: 900px) {
  .device-features-running-man-image {
    display: block;
    margin-left: 10px;
    height: auto;
    width: 300px;
  }
}
@media screen and (min-width: 1200px) {
  .device-features-running-man-image {
    display: block;
    margin-left: 10px;
    height: auto;
    width: auto;
    /* border-style: solid; */
  }
}

.device-features-text-content-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media screen and (min-width: 900px) {
  .device-features-text-content-card {
    max-width: 500px;
  }
}
@media screen and (min-width: 1200px) {
  .device-features-text-content-card {
    max-width: 680px;
    align-items: flex-start;
  }
}

.device-features-between-gap {
  display: none;
}
@media screen and (min-width: 900px) {
  .device-features-between-gap {
    display: block;
    width: 30px;
    flex-shrink: 0;
  }
}

.device-features-main-heading {
  color: var(--font-color-blue);
  font-size: var(--font-size-md);
  font-family: var(--font-family);
  font-weight: 600;
  text-align: center;
  margin-bottom: 30px;
}
@media screen and (min-width: 900px) {
  .device-features-main-heading {
    font-size: var(--font-size-lg);
  }
}
@media screen and (min-width: 1200px) {
  .device-features-main-heading {
    text-align: start;
    font-size: var(--font-size-xlg);
  }
}

.device-features-main-heading-span-1 {
  color: var(--font-color-orange);
}

.device-features-main-heading-span-2 {
  color: var(--font-color-orange);
  position: relative;
}
.device-features-main-heading-span-2::after {
  content: "";
  position: absolute;
  width: 98%;
  border-top: solid 3.5px var(--font-color-orange);
  left: 0;
  bottom: -10px;
  border-top-left-radius: 90%;
  border-top-right-radius: 0%;
  height: 10px;
}

.device-features-list-card {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}
@media screen and (min-width: 900px) {
  .device-features-list-card {
    justify-content: flex-start;
  }
}

.device-features-list-item {
  height: 130px;
  width: 130px;
  background-color: #f2f2f5;
  padding: 20px;
  border-radius: 24px;
}
@media screen and (min-width: 1200px) {
  .device-features-list-item {
    height: 192px;
    width: 212px;
    background-color: #f2f2f5;
    padding: 20px;
    border-radius: 24px;
  }
}

.device-features-list-item-image {
  height: 20px;
  width: auto;
  margin-bottom: 5px;
}
@media screen and (min-width: 1200px) {
  .device-features-list-item-image {
    height: 32px;
    width: auto;
    margin-bottom: 35px;
  }
}

.device-features-list-item-feature {
  color: var(--font-color-dark);
  font-size: 13px;
  font-weight: 500;
  font-family: var(--font-family);
  margin-bottom: 10px;
}
@media screen and (min-width: 1200px) {
  .device-features-list-item-feature {
    font-size: 20px;
  }
}

.device-features-list-item-description {
  color: var(--font-color-dark);
  font-size: 11px;
  font-weight: 400;
  font-family: var(--font-family);
}
@media screen and (min-width: 1200px) {
  .device-features-list-item-description {
    font-size: 12px;
  }
}
