.footer-main {
  min-height: 50vh;
  max-width: 100vw;
  background-color: var(--bg-dark-blue);
}
.footer-content-card {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 50px;
  padding: 20px;
}
@media screen and (min-width: 600px) {
  .footer-content-card {
    padding: 40px;
  }
}
@media screen and (min-width: 1200px) {
  .footer-content-card {
    padding-left: 130px;
    padding-right: 130px;
    gap: 0;
    justify-content: space-between;
  }
}

.footer-media-card {
  /* margin-bottom: 30px; */
}

.footer-health-circle-image {
  height: 70px;
  width: 70px;
  margin-bottom: 15px;
}

.footer-media-main-heading {
  color: #fff;
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
}

.footer-media-list-card {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.footer-media-list-item-icon {
  height: 28px;
  width: 28px;
  color: #fff;
  cursor: pointer;
}

/*  */

.footer-find-here-card {
  /* display: flex; */
  /* margin-bottom: 30px; */
}

.footer-find-here-heading {
  color: #ffffffa2;
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 3.84px;
  margin-bottom: 20px;
}

.footer-find-here-list-card {
  display: flex;
  flex-direction: column;
  gap: 13px;
}

.footer-find-here-list-item {
  color: #fff;
  font-family: var(--font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
  cursor: pointer;
}

/*  */

.footer-about-card {
  /* margin-bottom: 30px; */
}

.footer-about-heading {
  color: #ffffffa2;
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 3.84px;
  margin-bottom: 20px;
}

.footer-about-list-card {
  display: flex;
  flex-direction: column;
  gap: 13px;
}

.footer-about-list-item {
  color: #fff;
  font-family: var(--font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
  cursor: pointer;
}

/*  */

.footer-find-us-card {
  /* margin-top: -100px; */
}

.footer-find-us-heading {
  color: #ffffffa2;
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 3.84px;
  margin-bottom: 20px;
}

.footer-find-us-address-card {
  width: 280px;
  height: 260px;
  border-radius: 24px;
  background: #3e5078;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
@media screen and (min-width: 1200px) {
  .footer-find-us-address-card {
    width: 300px;
    height: 280px;
    padding: 25px;
  }
}

.footer-find-us-address-mail-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.footer-find-us-address-mail-icon {
  color: var(--font-color-white);
  height: 20px;
  width: 20px;
}

.footer-find-us-address-mail-text {
  color: #fff;
  font-family: var(--font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.footer-find-us-address-phone-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.footer-find-us-address-phone-icon {
  color: var(--font-color-white);
  height: 20px;
  width: 20px;
}

.footer-find-us-address-phone-text {
  color: #fff;
  font-family: var(--font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.footer-find-us-address-location-card {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
}

.footer-find-us-address-location-icon {
  color: var(--font-color-white);
  height: 20px;
  width: 20px;
  flex-shrink: 0;
}

.footer-find-us-address-location-text {
  color: #fff;
  font-family: var(--font-family);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 136%;
}
@media screen and (min-width: 1200px) {
  .footer-find-us-address-location-text {
    line-height: 160%;
  }
}

.footer-bubble-image-card {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.footer-bubble-image {
  height: 100px;
  width: 120px;
}
@media screen and (min-width: 900px) {
  .footer-bubble-image {
    height: 150px;
    width: auto;
  }
}
