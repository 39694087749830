.personal-health-circle-main {
  min-height: 100vh;
  max-width: 100vw;
  background-image: linear-gradient(-50deg, #4ba8eb60, #dddbdb79, #4ba8eb60);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.personal-health-circle-image-card {
  min-height: 100vh;
  min-width: 100%;
  background-image: repeating-radial-gradient(
    circle,
    #bfbfbf44,
    transparent 15%
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.personal-health-circle-image {
  height: 350px;
  width: 350px;
}
@media screen and (min-width: 600px) {
  .personal-health-circle-image {
    height: 600px;
    width: 600px;
  }
}
@media screen and (min-width: 900px) {
  .personal-health-circle-image {
    height: 800px;
    width: 800px;
  }
}
