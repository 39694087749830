.mobile-app-main {
  min-height: 70vh;
  max-width: 100vw;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin-bottom: -100px;
}

@media screen and (min-width: 600px) {
  .mobile-app-main {
    min-height: 80vh;
    padding: 40px;
  }
}
@media screen and (min-width: 1200px) {
  .mobile-app-main {
    padding-left: 130px;
    padding-right: 130px;
  }
}

.mobile-app-mobile-view-card {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
@media screen and (min-width: 900px) {
  .mobile-app-mobile-view-card {
    display: none;
  }
}

.mobile-app-image {
  height: auto;
  width: 250px;
}

.mobile-app-text-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.mobile-app-heading-1 {
  color: var(--font-color-blue);
  font-size: var(--font-size-m);
  font-weight: 500;
  margin-bottom: 10px;
}

.mobile-app-heading-2 {
  color: var(--font-color-blue);
  font-size: var(--font-size-md);
  font-weight: 600;
  margin-bottom: 20px;
}

.mobile-app-paragraph {
  color: var(--font-color-blue);
  font-size: 15px;
  font-weight: 300;
  margin-bottom: 30px;
}

.mobile-app-button-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.mobile-app-playstore-button {
  width: 130px;
  height: 35px;
  background-color: transparent;
  border-style: none;
}
.mobile-app-playstore-button-image {
  width: 130px;
  height: 35px;
}

.mobile-app-know-more-button {
  width: 130px;
  height: 35px;
  border-style: none;
  border-radius: 16px;
  background: var(--font-color-blue);
  color: var(--font-color-white);
  text-align: center;
  font-family: var(--font-family);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.mobile-app-content-bg {
  display: none;
}
@media screen and (min-width: 900px) {
  .mobile-app-content-bg {
    background-image: url("../../../Assets/mobile-app-bg.png");
    height: 373px;
    width: 90%;
    max-width: 1180px;
    background-size: cover;
    /* border-style: solid; */
    border-radius: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* margin-top: 0px; */
  }
  .mobile-app-image {
    margin-top: -130px;
    height: auto;
    width: 400px;
  }
  .mobile-app-text-card {
    max-width: 587px;
    padding: 20px;
    align-items: flex-start;
    text-align: start;
  }

  .mobile-app-heading-1 {
    color: var(--font-color-white);
    font-size: var(--font-size-m);
    font-weight: 500;
    margin-bottom: 10px;
  }

  .mobile-app-heading-2 {
    color: var(--font-color-white);
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .mobile-app-paragraph {
    color: #ffffffd2;
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 30px;
  }

  .mobile-app-button-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
  }

  .mobile-app-playstore-button {
    width: 165px;
    height: 55.829px;
    background-color: #ffffff;
    border-style: none;
    border-radius: 16px;
    cursor: pointer;
  }

  .mobile-app-know-more-button {
    width: 165px;
    height: 55.829px;
    border-style: none;
    border-radius: 16px;
    background: #ffffff3d;
    color: var(--font-color-white);
    text-align: center;
    font-family: var(--font-family);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    cursor: pointer;
  }
}

@media screen and (min-width: 1200px) {
  .mobile-app-image {
    margin-top: -130px;
    height: auto;
    width: 420px;
  }
  .mobile-app-text-card {
    max-width: 587px;
    padding: 20px;
    align-items: flex-start;
    text-align: start;
  }

  .mobile-app-heading-1 {
    color: var(--font-color-white);
    font-size: var(--font-size-md);
    font-weight: 500;
    margin-bottom: 10px;
  }

  .mobile-app-heading-2 {
    color: var(--font-color-white);
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .mobile-app-paragraph {
    color: #ffffffd2;
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 30px;
  }

  .mobile-app-button-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
  }

  .mobile-app-playstore-button {
    width: 165px;
    height: 55.829px;
    background-color: #ffffff;
    border-style: none;
    border-radius: 16px;
  }

  .mobile-app-know-more-button {
    width: 165px;
    height: 55.829px;
    border-style: none;
    border-radius: 16px;
    background: #ffffff3d;
    color: var(--font-color-white);
    text-align: center;
    font-family: var(--font-family);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }
}

/* .mobile-app-know-more-button-next-arrow {
} */
